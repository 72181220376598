import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
} from '../../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../../types/global_types'
import { getConfig } from '../../../config'
import { TickerDropdown } from '../../dropdowns/TickerDropdown'
import { TickerModal } from '../../modals/TickerModal'
import { Backtest } from '../../../types/backtest_types'
const config = getConfig() as any

interface TickerModalPositionSummaryProps {
  symbol: string
  backtest?: Backtest | null
}
export const TickerModalPositionSummary = (props: TickerModalPositionSummaryProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  if (!user || !uld) return null

  const {symbol, backtest} = props
  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData.POSITIONS[symbol]


  return (
    <div className='generic-content-container modal-version' style={{height: 200}}>
      <div className='generic-title'>Position summary</div>
      <div className='row'>
        <div className='generic-content-column narrower'>
          <div className='generic-content-item'>
            <div>qty</div>
            <div>{valueForTable('qty', LD_DATA_SOURCE?.qty)}</div>
          </div>
          <div className='generic-content-item'>
            <div>avg_entry_price</div>
            <div>{valueForTable('price', LD_DATA_SOURCE?.avg_entry_price)}</div>
          </div>
          <div className='generic-content-item'>
            <div>cost_basis</div>
            <div>{valueForTable('price', LD_DATA_SOURCE?.cost_basis)}</div>
          </div>
          <div className='generic-content-item'>
            <div>mkt_value</div>
            <div>{valueForTable('price', LD_DATA_SOURCE?.mkt_value)}</div>
          </div>
          <div className='generic-content-item'>
            <div>day_unrealized_pl</div>
            <div>{valueForTable('price', LD_DATA_SOURCE?.day_unrealized_pl)}</div>
          </div>
          <div className='generic-content-item'>
            <div>day_unrealized_pl_pc</div>
            <div>{valueForTable('price_pc', LD_DATA_SOURCE?.day_unrealized_pl_pc)}</div>
          </div>
          <div className='generic-content-item'>
            <div>unrealized_pl</div>
            <div>{valueForTable('price', LD_DATA_SOURCE?.unrealized_pl)}</div>
          </div>
          <div className='generic-content-item'>
            <div>unrealized_pl_pc</div>
            <div>{valueForTable('price_pc', LD_DATA_SOURCE?.unrealized_pl_pc)}</div>
          </div>
        </div>                  
      </div>
    </div>      
  )
}