import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
} from '../../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  appTabAtom
} from '../../../types/global_types'
import { getConfig } from '../../../config'
import { TickerDropdown } from '../../dropdowns/TickerDropdown'
import { TickerModal } from '../../modals/TickerModal'
import { Backtest } from '../../../types/backtest_types'
import { Icon } from '../../reusable'
import { db } from '../../../firebase'
const config = getConfig() as any

interface TickerModalWatchlistsSummaryProps {
  symbol: string
  backtest?: Backtest | null
}
export const TickerModalWatchlistsSummary = (props: TickerModalWatchlistsSummaryProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ appTab, setAppTab ] = useAtom(appTabAtom)
  const [, setOpenModal] = useAtom(openModalAtom)


  if (!user || !uld) return null

  const {symbol, backtest} = props
  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData.POSITIONS[symbol]


  return (
    <div className='generic-content-container modal-version'>
      <div className='generic-title'>Watchlists</div>
      <div className='row'>
        <div className='generic-content-column narrower'>
          {user.watchlists.filter(w => w.tickers.includes(symbol)).sort((a, b) => a.name.localeCompare(b.name)).map(watchlist => {
            return <div className='generic-content-item'>
              <div>{watchlist.name}</div>
              <Icon 
                icon='arrow-up-right'
                set='sharp-solid'
                size={9}
                onClick={() => {
                  setOpenModal(null)
                  db.collection('users').doc(user.uid).update({selectedWatchlistIdHomeTab: watchlist.id})
                }}
              />                
            </div>
          })}            
        </div>                  
      </div>
    </div>      
  )
}