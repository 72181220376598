import React, { useState } from 'react'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  ordersAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { BORDER_INPUT, SECOND_SURFACE, TEXT_GRAY_LIGHT, formatDateForTable, formatNumberForTable, handleMUISorting, tableSX, valueForTable, roundToSigFigs } from '../../logic/u'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { TickerModalActionBtns } from './ticker_modal_components/TickerModalActionBtns'
import { TickerModalMarketSummary } from './ticker_modal_components/TickerModalMarketSummary'
import { TickerModalPositionSummary } from './ticker_modal_components/TickerModalPositionSummary'
import { TickerModalWatchlistsSummary } from './ticker_modal_components/TickerModalWatchlistsSummary'
import { Icon } from '../reusable'
import { AddTickersModal } from './AddTickersModal'
import { Minichart } from '../minichart/Minichart'
import { OrdersTable } from '../tables/OrdersTable'

interface TickerModalProps {
  symbol: string
  orderId?: string
  goBackToAddTickersModal?: boolean
}

export const TickerModal = (props: TickerModalProps) => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)

  const { symbol, orderId, goBackToAddTickersModal } = props

  const isPosition = Object.keys(uld?.liveData.POSITIONS).includes(symbol)

  if (!user || !uld) return null

  const symbolData = uld.liveData[symbol]
  if (!symbolData) {
    console.log(`Symbol ${symbol} not found in uld.liveData, MARC TODO`)
    return null
  }

  return (
    <>
      <div
        className='modal-scrim'
        onClick={() => {
          setOpenModal(null)
        }}
      />
      <div
        className='modal'
        style={{
          height: 650,
          width: 1000,
          top: 60,
          left: 'calc(50vw - 500px)'
        }}
      >
        <div className='top' style={{height: '100%'}}>
          <div className='title-row'>
            <div className='row'>
              {goBackToAddTickersModal && <Icon
                icon='arrow-left'
                set='sharp-solid'
                size={16}
                style={{marginTop: 2}}
                className='margin-right-10'
                onClick={() => {
                  setOpenModal(<AddTickersModal />)
                }}
              />}
              <div>{symbol}</div>
              <div
                className='row font-size-10'
                style={{
                  marginTop: 6.5,
                  marginLeft: 25,
                  color: TEXT_GRAY_LIGHT
                }}
              >
                <div>
                  {uld.liveData[symbol]?.price} current_price
                </div>
                {isPosition && <>
                  <div style={{marginLeft: 5, marginRight: 5}} >{symbolData.price > symbolData.avg_entry_price ? '>' : '<'}</div>
                  <div>
                    {symbolData.avg_entry_price} avg_entry_price
                  </div>
                </>}
              </div>
            </div>
            <TickerModalActionBtns
              symbol={symbol}
              orderId={orderId}
            />
          </div>
          <div className='modal-body' style={{height: 'calc(100% - 61px)'}}>
            <div className='row width-full' style={{gap: 10, height: '100%'}}>
              <div className='column' style={{width: 250, height: '100%'}}>
                <div className='white-text bold margin-bottom-10'>Overview</div>
                <div className='column' style={{width: '100%', gap: 10, height: '100%'}}>
                  <TickerModalMarketSummary
                    symbol={symbol}
                  />
                  <TickerModalPositionSummary
                    symbol={symbol}
                  />
                  <TickerModalWatchlistsSummary
                    symbol={symbol}
                  />
                </div>
              </div>
              <div className='column' style={{flex: 1}}>
                {/* Mini chart */}
                <div style={{height: 362}}>
                  <Minichart ticker={symbol} />
                </div>

                {/* Orders pane */}
                <div style={{height: 200}}>
                  <div className='white-text bold margin-bottom-10'>Orders</div>
                  <OrdersTable
                    symbol={symbol}
                    orderId={orderId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}