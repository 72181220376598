import './NewLeftPane.scss'
import Split from 'react-split'
import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  consoleOutputAtom,
  loggedInUserAtom,
  openModalAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { Icon, Input } from '../reusable';
import { DeploymentDropdown } from '../dropdowns/DeploymentDropdown';
import { CreateTransferModal } from '../modals/CreateTransferModal';
import { CreateOrderModal } from '../modals/CreateOrderModal';
import { RunCodeModal } from '../modals/RunCodeModal';
import { AddTickersModal } from '../modals/AddTickersModal';
import { NewTickersList } from './NewTickersList';
import { NewStateList } from './NewStateList';
import { NewBalancesList } from './NewBalancesList';
import { NewStrategiesList } from './NewStrategiesList';
import { NewWatchlistsList } from './NewWatchlistsList';
import { NewBacktestsList } from './NewBacktestsList';
import { NewTablesList } from './NewTablesList';
import { CreateStrategyModal } from '../modals/CreateStrategyModal';
import { CreateWatchlistModal } from '../modals/CreateWatchlistModal';
import { CreateBacktestModal } from '../modals/CreateBacktestModal';
import { WatchlistSelectorDropdown } from '../dropdowns/WatchlistsSelectorDropdown';
import { AccountDropdown } from '../dropdowns/AccountDropdown';
import { LayoutDropdown } from '../dropdowns/LayoutDropdown';
import { valueForTable } from '../../logic/u';

export const NewLeftPane = () => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const deploymentDropdownRef = useRef<HTMLDivElement>(null);
  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const accountDropdownRef = useRef<HTMLDivElement>(null);
  const ellipsesDropdownRef = useRef<HTMLDivElement>(null);
  const [, setConsoleOutput] = useAtom(consoleOutputAtom)

  const SECTIONS = [
    {
      display: 'Strategies',
      value: 'strategies',
      section: <NewStrategiesList />,
      plusModal: <CreateStrategyModal />,      
    },
    {
      display: 'Tickers',
      value: 'tickers',
      section: <NewTickersList />,
      showFilterDropdown: (left, top) => (
        <WatchlistSelectorDropdown 
          left={left} 
          top={top + 18} 
          onClose={() => setOpenDropdown(null)} 
        />
      ),
    },
    {
      display: 'Balances',
      value: 'balances',
      section: <NewBalancesList />,      
    },
    {
      display: 'Unrealized return',
      value: 'unreallized_return',
      section: <NewBalancesList category='unrealized_return' />,      
    },
    {
      display: 'Realized return',
      value: 'realized_return',
      section: <NewBalancesList category='realized_return' />,      
    },
    {
      display: 'Misc',
      value: 'misc',
      section: <NewBalancesList category='misc' />,      
    },
    {
      display: 'State',
      value: 'state',
      section: <NewStateList />,
    },
    {
      display: 'Watchlists',
      value: 'watchlists',
      section: <NewWatchlistsList />,
      plusModal: <CreateWatchlistModal />,
    },
    {
      display: 'Tables',
      value: 'tables',
      section: <NewTablesList />,
    },
    {
      display: 'Backtests',
      value: 'backtests',
      section: <NewBacktestsList />,
      plusModal: <CreateBacktestModal />,
    },
  ];  

  if (!user) return null
  if (!uld || !uld.liveData) return null

  const balances = uld.liveData?.BALANCES

  return (
    <div className={'new-left-pane'}>
      {openDropdown}
      <div className='header'>
        <div className='row'>  
          <div
            className='pointer'
            ref={accountDropdownRef}
            onClick={(e) => {
              e.stopPropagation()
              if (accountDropdownRef.current) {
                const rect = accountDropdownRef.current.getBoundingClientRect(); // Get the position of the element
                setOpenDropdown(
                  <AccountDropdown 
                    left={rect.left}
                    top={rect.bottom}
                    onClose={() => setOpenDropdown(null)}
                  />
                );              
              }
            }}
          >
            <Icon 
              icon={'circle-' + user.alpacaObject.identity.given_name[0]}
              set='sharp-solid'
              size={15}
              style={{marginTop: 1, marginRight: 5}}
            />              
          </div>       
          <div title={'equity'} className={'white-text bold'} >
            {valueForTable('equity', balances?.equity)}
          </div>          
        </div>       
        <div className='row'>
          <div title={'day_equity_change'} className={'margin-left-5 bold' + (balances?.day_equity_change >= 0 ? ' pos' : ' neg')}>
            {valueForTable('day_equity_change', balances.day_equity_change)}
          </div>
          <div title={'day_equity_change_pc'} className={'margin-left-5 bold' + (balances?.day_equity_change >= 0 ? ' pos' : ' neg')}>
            {'[' + valueForTable('day_equity_change_pc', balances.day_equity_change_pc) + ']'}
          </div>               
        </div>
      </div>
      <div className='new-left-pane-main-container'>
        <div className='search-container'>
          <div 
            className='search-bar'
            onClick={() => {
              setOpenModal(<AddTickersModal />)
            }}
          >
            <div className='row'>
              <Icon 
                icon='magnifying-glass'
                size={16}
                style={{marginRight: 11, marginTop: 0}}
                className='search-icon'
                onClick={() => {
                  //
                }}
              />  
              <div>Search ticker</div>
            </div>
            <div className='row'>
              <Icon 
                icon='command'
                size={7}
                className='search-icon'
                style={{marginRight: 5}}
              />
              <div>K</div>
            </div>
          </div>
        </div>
        <div className='actions-container'>
          <div 
            className='action-row'
            onClick={() => {
              setOpenModal(<CreateTransferModal />)
            }}
          >
            <div className='row'>
              <Icon 
                icon='building-columns'
                set='sharp-solid'
                size={12}
                style={{marginRight: 10, marginTop: .5}}
                className='action-icon'              
              />
              <div>Transfer</div>
            </div>
          </div>
          <div 
            className='action-row'
            onClick={() => {
              setOpenModal(<CreateOrderModal />)
            }}
          >
            <div className='row'>
              <Icon 
                icon='receipt'
                set='sharp-solid'
                size={10}
                style={{marginRight: 11, marginTop: .75, marginLeft: .75}}
                className='action-icon'              
              />
              <div>Order</div>
            </div>
          </div>          
          <div 
            className='action-row'
            onClick={() => {
              setConsoleOutput([])            // single-console-output
              setOpenModal(<RunCodeModal />)
            }}
          >
            <div className='row'>
              <Icon 
                icon='rectangle-code'
                set='sharp-solid'
                size={12}
                style={{marginRight: 10, marginTop: 1.5}}
                className='action-icon'              
              />
              <div>Run some code</div>
            </div>
          </div>          
          <div 
            ref={deploymentDropdownRef}
            className='action-row'
            onClick={(e) => {
              e.stopPropagation()
              if (deploymentDropdownRef.current) {
                const rect = deploymentDropdownRef.current.getBoundingClientRect(); // Get the position of the element                        
                setOpenDropdown(<DeploymentDropdown 
                  left={rect.left + 10}
                  top={rect.bottom}
                  onClose={() => setOpenDropdown(null)}
                />)                                               
              }
            }}
          >
            <div className='row'>
              <Icon 
                icon='square-bolt'
                set='sharp-solid'
                size={12}
                style={{marginRight: 10, marginTop: 1.25}}
                className='action-icon'              
              />
              <div>Deploy strategies</div>
            </div>
          </div>          
        </div>
        <div className='main-list-container'>
          {SECTIONS.map(s => {
            const hiddenLeftPaneLists = user.hiddenLeftPaneLists ?? [];
            const updatedLists = hiddenLeftPaneLists.includes(s.value)
              ? hiddenLeftPaneLists.filter(item => item !== s.value)
              : [...hiddenLeftPaneLists, s.value];

            let sectionContainerClass = 'section-container'
            if (hiddenLeftPaneLists.includes(s.value)) {
              sectionContainerClass += ' closed'
            }              
            return <div className={sectionContainerClass}>
              <div 
                className={'section-header'}
                onClick={() => {
                  db.collection('users').doc(user.uid).update({ hiddenLeftPaneLists: updatedLists });
                }}
              >
                <div className='row'>
                  <div>{s.display}</div>
                  <Icon 
                    icon={hiddenLeftPaneLists.includes(s.value) ? 'caret-right' : 'caret-down'}
                    set='sharp-solid'
                    size={hiddenLeftPaneLists.includes(s.value) ? 6 : 8}
                    style={{marginLeft: 7, marginTop: hiddenLeftPaneLists.includes(s.value) ? 2 : .5}}
                    className='section-icon'                    
                  />
                </div>
                {s.showFilterDropdown && 
                  <div 
                    ref={filterDropdownRef}
                    className='section-icon-container'
                    onClick={(e) => {
                      e.stopPropagation()
                      if (filterDropdownRef.current) {
                        const rect = filterDropdownRef.current.getBoundingClientRect(); // Get the position of the element                        
                        setOpenDropdown(s.showFilterDropdown(rect.left, rect.top))                                               
                      }
                    }}
                    
                  >
                    <Icon
                      icon='bars-filter'
                      set='sharp-solid'
                      size={10}
                      style={{marginTop: -.5}}
                      className='section-icon'
                    />
                  </div>
                }
                {s.plusModal && 
                  <div 
                    className='section-icon-container'
                    onClick={(e) => {
                      e.stopPropagation()
                      setOpenModal(s.plusModal)
                    }}
                  >
                    <Icon
                      icon='plus'
                      set='sharp-solid'
                      size={10}
                      style={{marginTop: -.5}}
                      className='section-icon'
                    />
                  </div>
                }
              </div>
              {hiddenLeftPaneLists?.includes(s.value) ? null : 
                <div className='section-body'>{s.section}</div>
              }
            </div>
            
          })}
        </div>

      </div>
      <div className='header bottom'>
        <div className='row'>
          <div className='orange-tag' style={{marginLeft: 0}}>Plan info</div>
        </div>      
        <div className='row'>
          <div             
            ref={ellipsesDropdownRef}
            className='pointer'
            onClick={(e) => {
              e.stopPropagation()
              if (ellipsesDropdownRef.current) {
                const rect = ellipsesDropdownRef.current.getBoundingClientRect(); // Get the position of the element
                setOpenDropdown(
                  <LayoutDropdown 
                    left={rect.left}
                    top={rect.top - 10}
                    onClose={() => setOpenDropdown(null)}
                  />
                );               
              }
            }}
            
          >
            <Icon
              icon='ellipsis'
              set='sharp-solid'
              size={14}
              style={{marginTop: -.5}}
              className='section-icon'
              onClick={() => {
                // for hover
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}