import React, { useEffect, useState } from 'react'
import { Modal, DropdownSelect, Input, CurrencyInput, IntInput } from '../reusable'
import axios  from 'axios'
import { currentUser } from '../../firebase'
import { getConfig } from '../../config'
import { formatStockPrice, returnPaneContextObj, set_phantom_log, updateLiveData, } from '../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  phantomLogAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { ErrorModal } from './ErrorModal'
import { TickerModal } from './TickerModal'
import { set } from 'lodash'
const config = getConfig() as any

export interface CreateOrderModalProps {
  symbol?: string
  sell?: boolean
  limitPrice?: number
  stopPrice?: number
  showBack?: boolean
  orderId?: string // so we can pass back to ticker modal if needed
}

export function CreateOrderModal(props: CreateOrderModalProps) {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [ , setPhantomLog ] = useAtom(phantomLogAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  let initialSymbol = ''
  const paneContext = returnPaneContextObj(user ? user : undefined, initialSymbol)
  if (paneContext?.visiblePaneThings[0]?.length) {
    initialSymbol = paneContext.visiblePaneThings[0]
  }
  if (props.symbol) {
    initialSymbol = props.symbol
  }
  const [ symbol, setSymbol ] = useState<string>(initialSymbol)

  const [ side, setSide ] = useState<'buy' | 'sell'>(props.sell ? 'sell' : 'buy')

  let initialOrderType = 'market'
  if (props.limitPrice) {
    initialOrderType = 'limit'
  }
  if (props.stopPrice) {
    initialOrderType = 'stop'
  }
  const [ orderType, setOrderType ] = useState<string>(initialOrderType)
  const [ limitPrice, setLimitPrice ] = useState<number>(props.limitPrice ? props.limitPrice : 0)
  const [ stopPrice, setStopPrice ] = useState<number>(props.stopPrice ? props.stopPrice : 0)
  const [ tradeIn, setTradeIn ] = useState<string>('shares')
  const [ quantity, setQuantity ] = useState<number>(1)
  const [ notional, setNotional ] = useState<number>(0)
  const [ timeInForce, setTimeInForce ] = useState<string>('day')

  // Handles buttonEnabled. Note we only include inputs that can be blank or 0.
  let errorMessage = ''
  useEffect(() => {
    if (orderType === 'market') {
      if (tradeIn === 'shares') {
        setButtonEnabled(!!symbol && !!quantity && !errorMessage)
      }
      if (tradeIn === 'dollars') {
        setButtonEnabled(!!symbol && !!notional && !errorMessage)
      }
    }
    if (orderType === 'limit') {
      setButtonEnabled(!!symbol && !!quantity && !!limitPrice && !errorMessage)
    }
    if (orderType === 'stop') {
      setButtonEnabled(!!symbol && !!quantity && !!stopPrice && !errorMessage)
    }
    if (orderType === 'stop_limit') {
      setButtonEnabled(!!symbol && !!quantity && !!limitPrice && !!stopPrice && !errorMessage)
    }
  }, [symbol, side, orderType, limitPrice, stopPrice, tradeIn, quantity, notional, timeInForce, errorMessage])

  if (!user) return null
  if (!uld) return null

  // Asset options, deduped
  const positions = Object.keys(uld.liveData.POSITIONS)
  const DSTcharts = Object.keys(user.charts)
  let assetOptions: any = positions.concat(DSTcharts)
  .reduce((acc: any, asset) => {
    if (!acc.temp.includes(asset)) {
      acc.result.push({ display: asset, value: asset })
      acc.temp.push(asset)
    }
    return acc;
  }, { temp: [], result: [] })
    .result

  // Side
  if (side === 'sell') {
    assetOptions = positions.map((asset) => {
      return {display: asset, value: asset}
    })
  }

  // Side options
  let sideOptions = [
    {display: 'Buy', value: 'buy'},
    {display: 'Sell', value: 'sell'}
  ]

  // Order type options
  let orderTypeOptions = [
    {display: 'Market', value: 'market'},
    {display: 'Limit', value: 'limit'},
    {display: 'Stop', value: 'stop'},
    {display: 'Stop Limit', value: 'stop_limit'},
  ]

  // Time in force options
  let timeInForceOptions = [
    {display: 'Day', value: 'day'},
    {display: 'GTC - Good till Canceled', value: 'gtc'},
  ]
  if (orderType === 'market') {
    timeInForceOptions.push({display: 'FOK - Fill or Kill', value: 'fok'})
    timeInForceOptions.push({display: 'IOC - Immediate or Cancel', value: 'ioc'})
  }

  // Order totals
  const marketPrice = uld.liveData[symbol]?.price || uld.liveData.POSITIONS[symbol]?.current_price
  let estimatedOrderAmount = uld.liveData[symbol]?.price * quantity
  if (orderType === 'limit') {
    estimatedOrderAmount = limitPrice * quantity
  } else if (orderType === 'stop') {
    estimatedOrderAmount = stopPrice * quantity
  } else if (orderType === 'stop_limit') {
    estimatedOrderAmount = limitPrice * quantity
  }

  // Buying power error
  const buyingPower = Number(uld.liveData?.BALANCES?.buying_pwr)
  if (side === 'buy' && buyingPower < Number(uld.liveData[symbol]?.price * quantity)) {
    errorMessage = 'Insufficient buying power'
  }

  // Sell error
  if (side === 'sell' && !Number(uld.liveData?.POSITIONS[symbol]?.qty)) {
    errorMessage = `No shares to sell of ${symbol}`
  }

  // Max quantity to sell error
  if (side === 'sell' && quantity > Number(uld.liveData?.POSITIONS[symbol]?.qty)) {
    errorMessage = `Max shares to sell of ${symbol} is ${uld.liveData?.POSITIONS[symbol]?.qty}`
  }

  // Notional error
  if (tradeIn === 'dollars' && orderType !== 'market') {
    errorMessage = `Order type must be market when trading in dollars`
  }

  if (tradeIn === 'dollars' && timeInForce !== 'day') {
    errorMessage = `Time in force must be day when trading in dollars`
  }

  const orderForm = (
    <div className='column width-full'>

      {/* Asset */}
      <DropdownSelect
        label={'Asset'}
        width={362}
        options={assetOptions?.sort((a: any, b: any) => a.value[0]?.localeCompare(b.value[0]))}
        isAutocomplete={true}
        value={symbol}
        onChange={(val: string) => {
          setSymbol(val)
        }}
        autoFocus={true}
      />
      <div className='row width-full'>
        <div className='under-input-label'>
          {'Est. price: ' + formatStockPrice(marketPrice, true)}
        </div>
      </div>

      {/* Side , type */}
      <div className='row width-full margin-top-20'>
        <DropdownSelect
          label={'Side'}
          width={167}
          style={{marginRight: 10}}
          options={sideOptions}
          value={side}
          onChange={(val) => {
            setSide(val)
          }}
        />
        <DropdownSelect
          label={'Order type'}
          width={167}
          options={orderTypeOptions}
          value={orderType}
          onChange={(val) => {
            if (val === 'market') {
              setLimitPrice(0)
              setStopPrice(0)
            }
            if (val === 'limit') {
              setStopPrice(0)
            }
            if (val === 'stop') {
              setLimitPrice(0)
            }
            setOrderType(val)
          }}
        />
      </div>

      {/* Limit section */}
      {orderType === 'limit' ? <div className='row margin-top-20'>
        <CurrencyInput
          label={'Limit price'}
          width={380}
          value={limitPrice}
          onChange={(val) => {
            if (val === undefined) {
              setLimitPrice(0)
              return
            }
            setLimitPrice(val)
          }}
        />
      </div> : null}

      {/* Stop */}
      {orderType === 'stop' ? <div className='row margin-top-20'>
        <CurrencyInput
          label={'Stop price'}
          width={362}
          value={stopPrice}
          onChange={(val) => {
            if (val !== undefined) setStopPrice(val)
          }}
        />
      </div> : null}

      {/* Stop limit */}
      {orderType === 'stop_limit' ? <div className='row margin-top-20'>
        <CurrencyInput
          label={'Stop price'}
          width={179}
          value={stopPrice}
          onChange={(val) => {
            if (val !== undefined) setStopPrice(val)
          }}
        />
        <CurrencyInput
          label={'Limit price'}
          style={{marginLeft: 41}}
          width={179}
          value={limitPrice}
          onChange={(val) => {
            if (val !== undefined) setLimitPrice(val)
          }}
        />
      </div> : null}

      {/* Cash or quantity */}
      <div className={'row margin-top-20'}>
        <DropdownSelect
          label={'Trade in'}
          width={167}
          style={{marginRight: 10}}
          options={[
            {display: 'Shares', value: 'shares',},
            {display: 'Dollars', value: 'dollars',},
          ]}
          value={tradeIn}
          onChange={(val) => {
            setTradeIn(val)
            if (val === 'dollars') {
              setQuantity(0)
            }
            if (val === 'shares') {
              setNotional(0)
            }
          }}
        />
        {tradeIn === 'shares' ? <IntInput
          label={'Quantity'}
          value={quantity}
          onBlur={(val: number) => {setQuantity(val)}}
          onChange={(val: number) => {setQuantity(val)}}
        /> : null}
        {tradeIn === 'dollars' ? <CurrencyInput
          label={'Amount'}
          width={167}
          value={notional}
          onChange={(val) => {
            if (val === undefined) {
              setNotional(0)
              return
            }
            setNotional(parseFloat(val.toFixed(2)))
          }}
        /> : null}
      </div>

      <DropdownSelect
        label={'Time in force'}
        width={362}
        className='margin-top-20'
        options={timeInForceOptions}
        value={timeInForce}
        onChange={(val) => setTimeInForce(val)}
      />

      <div className='divider-green margin-bottom-20' style={{marginTop: 22}}></div>
      <div className='column width-full font-size-11 orange-text'>
        <div className='row space-between'>
          <div className=''>Est. buying power</div>
          <div className=''>{buyingPower ? formatStockPrice(buyingPower, true) : '-'}</div>
        </div>
        {tradeIn === 'shares' ? <div className='row space-between'>
          <div className=''>Est. order amount</div>
          <div className=''>{estimatedOrderAmount ? formatStockPrice(estimatedOrderAmount, true) : '-'}</div>
        </div> : null}
        {tradeIn === 'dollars' ? <div className='row space-between'>
          <div className=''>Est. order quantity</div>
          <div className=''>{notional ? formatStockPrice(notional / marketPrice, true) : '-'}</div>
        </div> : null}
      </div>

      {/* Error message, if relevant */}
      <div
        className={errorMessage ? 'font-size-10 red-text' : 'display-none'}
        style={{
          marginTop: 15,
          marginBottom: -10,
        }}
      >
        {errorMessage}
      </div>
    </div>
  )

  return (
    <Modal
      className=''
      title={'Order'}
      contents={[orderForm]}
      yesButtonText={'Submit'}
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      backIconOnClick={props.showBack ? () => setOpenModal(props.symbol ? <TickerModal symbol={props.symbol} orderId={props.orderId} /> : null) : null}
      onYes={async () => {
        setButtonIsLoading(true)
        set_phantom_log('Submitting order...', uld, setPhantomLog)
        setButtonIsLoading(false)
        setOpenModal(null)
        try {
          const token = await currentUser()?.getIdToken()
          const data: any = {
            symbol: symbol,
            side: side,
            order_class: 'simple',
            type: orderType,
            qty: tradeIn === 'shares' ? quantity : null,
            notional: tradeIn === 'dollars' ? notional : null,
            time_in_force: timeInForce,
            limit_price: limitPrice ? limitPrice : null,
            stop_price: stopPrice ? stopPrice : null,
            create_ee_log: true
          }
          const createOrderUrl = `${config.api_root_url}createOrder`
          await axios.post(createOrderUrl, data, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          updateLiveData(user)
          // setButtonIsLoading(false)
          // setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(null)
          setOpenModal(<ErrorModal errorMessage={error}/>)
          setPhantomLog(null)
        }
        updateLiveData(user)
      }}
    />
  )
}