import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
} from '../../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../../types/global_types'
import { getConfig } from '../../../config'
import { TickerDropdown } from '../../dropdowns/TickerDropdown'
import { TickerModal } from '../../modals/TickerModal'
import { Backtest } from '../../../types/backtest_types'
const config = getConfig() as any

interface TickerModalMarketSummaryProps {
  symbol: string
  backtest?: Backtest | null
}
export const TickerModalMarketSummary = (props: TickerModalMarketSummaryProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  if (!user || !uld) return null

  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData
  const {symbol, backtest} = props

  // debugger

  return (
    <div className='generic-content-container modal-version' style={{height: 200}}>
      <div className='generic-title'>Market summary</div>
      <div className='row'>          
        <div className='generic-content-column narrower'>
          <div className='generic-content-item'>
            <div>open</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE[symbol].o)}</div>
          </div>
          <div className='generic-content-item'>
            <div>high</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE[symbol].h)}</div>
          </div>
          <div className='generic-content-item'>
            <div>low</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE[symbol].l)}</div>
          </div>
          <div className='generic-content-item'>
            <div>close</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE[symbol].c)}</div>
          </div>
          <div className='generic-content-item'>
            <div>volume</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE[symbol].v).split('.')[0]}</div>
          </div>             
          <div className='generic-content-item'>
            <div>day_price_change</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE[symbol].day_price_change)}</div>
          </div>
          <div className='generic-content-item'>
            <div>day_price_change_pc</div>
            <div>{valueForTable('price_pc', LD_DATA_SOURCE[symbol].day_price_change_pc)}</div>
          </div>
        </div>       
      </div>
    </div>      
  )
}