// IMPORTANT - GO THROUGH 'FIX' 'TODO' ETC. BEFORE GOING LIVE!

import React, { useState, useEffect, useRef } from 'react'

import './App.scss'
// import './components/logged_out/SignUp.scss'

import axios from 'axios'

import { LoggedOutMenuBar } from './components/logged_out/LoggedOutMenuBar'
import { SignUp } from './components/logged_out/SignUp'
import { Login } from './components/logged_out/Login'
import { ResetPassword } from './components/logged_out/ResetPassword'
import { ResetPasswordConfirmationScreen } from './components/logged_out/ResetPasswordConfirmationScreen'
import { AlpacaSignUp } from './components/logged_out/alpaca_signup/AlpacaSignUp'
import { DividerPane } from './components/sidebars/DividerPane'
import { RightPane } from './components/sidebars/RightPane'
import { get_initial_daily_log } from './logic/initial_daily_log'
import { sort_orders } from './logic/order_record_utils'
import { BacktestTab } from './components/backtests/BacktestTab'
import { AccountTab } from './components/tabs/AccountTab'

import Split from 'react-split'

import { useAtom } from 'jotai'
import {
  appTabAtom,
  openModalAtom,
  loggedOutTabAtom,
  loggedInUserAtom,
  ordersAtom,
  transfersAtom,
  loadingAtom,
  allSymbolsAtom,
  userLiveDocAtom,
  streamingQuotesAtom,
  cmdKAtom, cmdSAtom, cmdOAtom, cmdTAtom,
  backtestsAtom,
  strategiesAtom,
  deploymentsAtom,
} from './types/global_types'

import {
  ORANGE,
  BACKGROUND,
  GREEN,
  registerUsage,
  haveWeTimedOut,
  updateLiveData,
  isMarketOpenOrWillItOpenSoon,
  uuid,
} from './logic/u'

import { getFirebase, authFunctionsOnWindow, currentUser, db } from './firebase'
import { getConfig } from './config'
import { Button, Icon } from './components/reusable'
import { BottomPane } from './components/sidebars/BottomPane'
import { Deployment, Strategy } from './types/user_types'
import { Backtest } from './types/backtest_types'
import { NewLeftPane } from './components/sidebars/NewLeftPane'
const config = getConfig() as any
const firebase = getFirebase()

const SMALL_SCREEN_THRESHOLD = 1080


function App() {
  authFunctionsOnWindow()

  /* USER & LIVE DATA */
  const [fireBaseToken, setFirebaseToken] = useState<string | null>(null)
  const [loggedInUser, setLoggedInUser] = useAtom(loggedInUserAtom)
  const [userLiveDoc, setUserLiveDoc] = useAtom(userLiveDocAtom)
  const [emailVerified, setEmailVerified] = useState<boolean>(false)
  const [, setOrders] = useAtom(ordersAtom)
  const [, setTransfers] = useAtom(transfersAtom)
  const [, setBacktests] = useAtom(backtestsAtom)
  const [, setStrategies] = useAtom(strategiesAtom)
  const [, setDeployments] = useAtom(deploymentsAtom)
  const [allSymbols, setAllSymbols] = useAtom(allSymbolsAtom)
  const hasAppAccess = loggedInUser?.signUpStage === 'Done'

  /* LAYOUT */
  const [loggedOutTab, setLoggedOutTab ] = useAtom(loggedOutTabAtom)
  const [appTab, setAppTab] = useAtom(appTabAtom)
  const [openModal, setOpenModal] = useAtom(openModalAtom)
  const [loading, setLoading] = useAtom(loadingAtom)
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < SMALL_SCREEN_THRESHOLD);
  const [emailVerBtnLoading, setEmailVerBtnLoading] = useState<boolean>(false)

  /* CHART */
  // const [selectedChart, setSelectedChart] = useAtom(selectedChartAtom)
  // const [selectedAnnotation, setSelectedAnnotation] = useAtom(selectedAnnotationAtom)
  // const [, setAnnotationInProgress] = useAtom(annotationInProgressAtom)
  // const [, setToolMode] = useAtom(toolModeAtom)
  // const [timeframe] = useAtom(intervalAtom)
  const [streamingQuotes, setStreamingQuotes] = useAtom(streamingQuotesAtom)

  /* KEYBOARD SHORTCUTS */
  const [, setCmdK] = useAtom(cmdKAtom)
  const [, setCmdS] = useAtom(cmdSAtom)
  const [, setCmdO] = useAtom(cmdOAtom)
  const [, setCmdT] = useAtom(cmdTAtom)

  /* Handle login/logout, subscribe to user and userLiveDoc */
  useEffect(() => {
    setLoading(true);
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          console.log(token)
          setFirebaseToken(token);
          setEmailVerified(user.emailVerified);

          // Subscribing to user updates and live document updates
          await subscribeToUserUpdates(user.uid);
          await subscribeToUserLiveDoc(user.uid);

          // console.log('Firebase token:', token);

          setLoading(false);
        } catch (error) {
          console.error("Error handling user auth state change:", error);
          setLoading(false);
        }
      } else {
        // Handle user not logged in
        setFirebaseToken(null);
        setLoggedInUser(null);
        setLoading(false);
        setLoggedOutTab('log_in');
        setAppTab('')
        console.log("No user logged in.");
      }
    });

    // Cleanup function for unsubscribing from auth state changes
    return () => {
      console.log("Unsubscribing from auth state changes.");
      unsubscribe();
    };
  }, []);


  /* FIREBASE SUBSCRIPTIONS */
  const subscribeToUserUpdates = async (userId) => {
    console.log('SUBSCRIBING TO USER SNAPSHOTS')
    const userDocRef = db.collection('users').doc(userId);

    const unsubscribe = userDocRef.onSnapshot((doc) => {
      const userData = doc.data();
      if (userData) {
        console.log('User data updated.');
        //@ts-ignore
        setLoggedInUser({ ...userData, id: doc.id }); // Assuming you spread userData correctly into state
        //@ts-ignore
        window.user = userData

        // Run runTick on page load, to make sure we have fresh data
        updateLiveData(loggedInUser)
      }
    });

    return unsubscribe; // Return the unsubscribe function for cleanup
  };

  // contains liveData, and everything that streams

  const subscribeToUserLiveDoc = async (userId: string) => {
    const docRef = db.collection('userLiveDocs').doc(userId);

    // First, check if the document exists
    const docSnapshot = await docRef.get();

    if (docSnapshot.exists) {
      // If the document exists, set up the snapshot listener
      docRef.onSnapshot((doc) => {
        const uld: any = doc.data();
        if (uld) {
          uld.id = doc.id;

          if (doc.id !== userId) {
            throw new Error('userLiveDoc id is not the same as the user id');
          }
          setUserLiveDoc(uld);



          // const newest_log = uld.cachedEELogs[0]
          // const newest_log_ts = new Date(newest_log.time_iso).getDate()

          //@ts-ignore
          window.uld = uld;
        }
      });
    } else {
      // If the document doesn't exist, create it
      console.log('No userLiveDoc exists for this user, creating one now');

      const initial_daily_log = get_initial_daily_log();
      await docRef.set({
        uid: userId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        cachedEELogs: [],
        cachedDayLogs: [initial_daily_log],
        symbolsFollowing: [],
        needsStreamingData: false,
        quotes: {},
        orders: [],
        transfers: [],
      });

      // Add the initial daily log to the user's EELogs subcollection
      await db.collection('users').doc(userId).collection('EELogs').add(initial_daily_log);

      // Set up the snapshot listener after creating the document
      docRef.onSnapshot((doc) => {
        const uld: any = doc.data();
        if (uld) {
          uld.id = doc.id;
          setUserLiveDoc(uld);

          //@ts-ignore
          window.uld = uld;
        }
      });
    }
  };

  /*
  UPDATE LAST SEEN ON CRON JOB - this permits streaming to the userLiveDoc
  object from runSSEListeners. Affects userLiveDoc, not user
  */
  const isFirstRun = useRef(true);
  const updateLastSeen = async () => {
    if (!loggedInUser) return
    if (!userLiveDoc) return
    if (haveWeTimedOut()) return          // DO WE STILL NEED THIS?

    // If market is closed, no reason to leave us in !isActive
    if (userLiveDoc?.isActive) {
      if (!isMarketOpenOrWillItOpenSoon()) return
    }



    const now = firebase.firestore.FieldValue.serverTimestamp()
    const toSet: any = {
      lastSeen: now,
      isActive: true            // isActive probably not needed anymore
      }

    // What charts are open? We need to reset symbolsFollowing
    if (!userLiveDoc.symbolsFollowing?.length) {
      const sf: string[] = []
      if (loggedInUser.paneSettingsCH1) sf.push(loggedInUser.paneSettingsCH1)
      if (loggedInUser.paneSettingsCH2) sf.push(loggedInUser.paneSettingsCH2)
      if (loggedInUser.paneSettingsCH3) sf.push(loggedInUser.paneSettingsCH3)
      if (loggedInUser.paneSettingsCH4) sf.push(loggedInUser.paneSettingsCH4)
      if (sf.length) {
        toSet.symbolsFollowing = sf
        toSet.needsStreamingData = true
      }
    }

    await db.collection('userLiveDocs').doc(loggedInUser.uid).update(toSet)
    if (['ACTIVE', 'SUBMITTED'].includes(loggedInUser?.alpacaObject?.status)) {
      updateLiveData(loggedInUser)          // runTick call to update
    }
  }

  // FIX THIS
  // Subscribe to socket server
  const [socket, setSocket] = useState<any>(null);
  useEffect(() => {
    let wsAddress = config.websocket_endpoint
    const USE_LOCAL_WS = false
    if (USE_LOCAL_WS) {
      wsAddress = `ws://localhost:80`
    }

    console.log(`Trying to connect to ${wsAddress}`);
    const ws = new WebSocket(wsAddress);

    const symbols_to_follow: string[] = Object.keys(loggedInUser?.charts || {});

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
      ws.send(JSON.stringify({
        action: 'subscribe',
        symbols: symbols_to_follow
      }));
    };

    ws.onmessage = (event) => {
      const payload = JSON.parse(event.data);
      if (!symbols_to_follow.includes(payload.symbol)) return;
      const price = payload.data.price;
      const last_close = userLiveDoc?.liveData?.[payload.symbol]?.last_close;
      if (last_close) {
        payload.data.day_price_change = price - last_close;
        payload.data.day_price_change_pc = (payload.data.day_price_change / last_close) * 100;
      }
      setStreamingQuotes(prevQuotes => ({
        ...prevQuotes,
        [payload.symbol]: payload.data
      }));

      console.log(`New price for ${payload.symbol}: $${payload.data.price} at ${new Date(payload.data.timestamp).toLocaleString()}`);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    setSocket(ws);

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [loggedInUser?.charts, userLiveDoc?.socketServerId?.wsEndpoint]);

  // Refresh data when user returns to tab, reopens laptop, etc
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const last_ran_date = userLiveDoc?.liveDataLastUpdated?.toDate()
        let needs_refresh = false

        if (last_ran_date) {
          const seconds_ago = (new Date().getTime() - last_ran_date.getTime() / 1000)
          if (seconds_ago > 60) {
            needs_refresh = true
            console.log(`Updating liveData on account of return to visibility, last update was ${seconds_ago} seconds ago`);
          }
        } else {
          needs_refresh = true
        }
        if (needs_refresh) updateLiveData(loggedInUser);

      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [loggedInUser]);

  useEffect(() => {
    if (!loggedInUser) return
    if (!userLiveDoc) return
    if (isFirstRun.current) {
      updateLiveData(loggedInUser)
      updateLastSeen()
      isFirstRun.current = false
    }
    // const interval = setInterval(updateLastSeen, 60000)
    const interval = setInterval(() => {
      updateLastSeen()

      // Call runTick, only if it's more than 2 minutes old
      const uld_updated_at = new Date(userLiveDoc.updatedAt.seconds * 1000)
      // @ts-ignore
      const uld_updated_mins_ago = (new Date() - uld_updated_at) / (1000 * 60)
      if (uld_updated_mins_ago > 2) {
        updateLiveData(loggedInUser)
      }
      // updateLiveData(loggedInUser)
    }, 60000)
    return () => clearInterval(interval);
  }, [loggedInUser])

  // Load transfers into common mechanism
  useEffect(() => {
    if (!userLiveDoc) return
    setTransfers(userLiveDoc.transfers)
  }, [userLiveDoc])

  // Load orders into common mechanism
  useEffect(() => {
    if (!userLiveDoc) return
    const sorted_orders = sort_orders(userLiveDoc.orders)
    setOrders(sorted_orders)

  }, [userLiveDoc])

  // Load strategies into common mechanism
  useEffect(() => {
    if (!loggedInUser) return

    const strategiesRef = db
      .collection('users')
      .doc(loggedInUser.uid)
      .collection('strategies')

    const unsubscribe = strategiesRef.onSnapshot(snapshot => {
      const strategiesData = snapshot.docs.map(doc => {
        const data = doc.data()
        return {
          id: doc.id,
          ...data
        } as Strategy
      })
      strategiesData.sort((a, b) => a.index - b.index)
      setStrategies(strategiesData)

      // FOR DEBUGGING
      //@ts-ignore
      window.strategies = strategiesData
    })

    return () => unsubscribe()
  }, [loggedInUser])

  // Load deployments into common mechanism
  useEffect(() => {
    if (!loggedInUser) return

    const deploymentsRef = db
      .collection('users')
      .doc(loggedInUser.uid)
      .collection('deployments')

    const unsubscribe = deploymentsRef.onSnapshot(snapshot => {
      const deploymentsData = snapshot.docs.map(doc => {
        const data = doc.data()
        return {
          id: doc.id,
          ...data
        } as Deployment
      })
      deploymentsData.sort((a, b) => a.index - b.index)
      setDeployments(deploymentsData)
    })

    return () => unsubscribe()
  }, [loggedInUser])

  // Load backtests into common mechanism
  useEffect(() => {
    if (!loggedInUser) return

    const backtestsRef = db
      .collection('users')
      .doc(loggedInUser.uid)
      .collection('backtests')

    const unsubscribe = backtestsRef.onSnapshot(snapshot => {
      // const backtestsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      const backtestsData = snapshot.docs.map(doc => {
        const data = doc.data()
        return {
          id: doc.id,
          ...data
        } as Backtest
      })
      backtestsData.sort((a, b) => a.index - b.index)
      setBacktests(backtestsData)
    })

    return () => unsubscribe()
  }, [loggedInUser])

  // Convenience
  useEffect(() => {
    if (location.host.includes('staging')) {
      document.title = 'Tickerbot (STAGING)'
    }
    if (location.host.includes('localhost')) {
      document.title = 'Tickerbot (LOCAL)'
    }
  }, [])

  /* LOAD COMPLETE LIST OF ASSETS FOR LATER USE */
  useEffect(() => {
    if (allSymbols.length) return
    const loadAssets = async () => {
      const token = await currentUser()?.getIdToken()
      if (!token) return
      if (allSymbols.length !== 0) return
      const endpoint = `${config.api_root_url}getAllSymbols`
      const res = await axios.get(endpoint, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      const assets = res.data
      setAllSymbols(assets)
      console.log(`loaded details on ${assets.length} assets`)
    }
    loadAssets()
  }, [loggedInUser])


  // Ensure backend tickers and position watchlist are up to date
  useEffect(() => {
    if (!loggedInUser) return;

    const positionTickers = Object.keys(userLiveDoc?.liveData?.POSITIONS || {});
    const watchlists = loggedInUser.watchlists || [];
    const positionWatchlist = watchlists.find(w => w.name === 'positions')
    const positionWatchlistTickers = (positionWatchlist?.tickers || [])

    const newPositionWatchlistTickers: string[] = []

    positionWatchlistTickers.forEach(t => {
      if (positionTickers.includes(t)) {
        newPositionWatchlistTickers.push(t)
      }
    })

    positionTickers.forEach(t => {
      if (!newPositionWatchlistTickers.includes(t)) {
        newPositionWatchlistTickers.push(t)
      }
    })

    // Check if the position watchlist is up-to-date
    const isPositionWatchlistUpToDate =
      positionTickers.length === newPositionWatchlistTickers.length && // Check if lengths are equal
      positionTickers.slice().sort().every((ticker, index) => ticker === newPositionWatchlistTickers.slice().sort()[index]); // Compare each ticker

    if (!isPositionWatchlistUpToDate) {

      const updatedWatchlists = positionWatchlist
        ? watchlists.map(w =>
            w.name === 'positions'
              ? { ...w, tickers: newPositionWatchlistTickers, updatedAt: new Date() }
              : w
          )
        : [
            ...watchlists,
            {
              userId: loggedInUser.uid,
              id: uuid(),
              createdAt: new Date(),
              updatedAt: new Date(),
              name: 'positions',
              tickers: newPositionWatchlistTickers,
              isDefault: true,
            },
          ];

      // Update watchlists in the database
      db.collection('users').doc(loggedInUser.uid).update({ watchlists: updatedWatchlists });
    }

    // Ensure charts are updated to include all relevant tickers
    const allBackendTickers = Object.keys(loggedInUser.charts || {});
    const allWatchlistTickers = watchlists.map(w => w.tickers).flat();
    const allRelevantTickers = [...new Set(newPositionWatchlistTickers.concat(allWatchlistTickers))];
    const newCharts = { ...loggedInUser.charts };

    const isBackendTickersUpToDate =
    allBackendTickers.length === allRelevantTickers.length && // Check if lengths are equal
    allBackendTickers.slice().sort().every((ticker, index) => ticker === allRelevantTickers.slice().sort()[index]); // Compare each ticker

    if (isBackendTickersUpToDate) return;

    // Add missing tickers
    allRelevantTickers.filter(t => !allBackendTickers.includes(t)).forEach(t => {
      newCharts[t] = {
        symbol: t,
        display: t, // Check if this is needed
        annotations: {},
        indicators: {},
      };
    });

    // Remove unnecessary tickers
    allBackendTickers.filter(t => !allRelevantTickers.includes(t)).forEach(t => {
      delete newCharts[t];
    });

    // Update charts in the database
    db.collection('users').doc(loggedInUser.uid).update({ charts: newCharts });

  }, [loggedInUser, userLiveDoc]);



  /* KEYBOARD LISTENER */
  useEffect(() => {
    const handleKeydown = async (e) => {
      const ts = new Date().getTime().toString()
      // registerUsage()
      if (e.key === 'j' && e.metaKey) {
        e.preventDefault()
        setCmdK(ts)
      }
      else if (e.key === 's' && e.metaKey) {
        e.preventDefault()
        setCmdS(ts)
      }
      // else if (e.key === 't' && e.metaKey) {
      //   e.preventDefault()
      //   setCmdT(ts)
      // }
      // else if (e.key === 'o' && e.metaKey) {
      //   e.preventDefault()
      //   setCmdO(ts)
      // }

      // else if (e.key === 'p' && e.metaKey) {
      //   console.log('cmd + p')
      // } else if (e.key === '1' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('home')
      // } else if (e.key === '2' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('couplets')
      // } else if (e.key === '3' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('custom_data')
      // } else if (e.key === '4' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('logs')
      // } else if (e.key === '5' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('portfolio')
      // } else if (e.key === '6' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('account')
      // } else if (e.key === '7' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('help')
      // } else if (e.key === 'Backspace' || e.key === 'Delete') {
      //   if (selectedAnnotation && selectedChart) {
      //     // Delete annotation, if selected
      //     await delete_annotation({
      //       id: selectedAnnotation.id,
      //       user: loggedInUser,
      //       selectedChart,
      //     })
      //   }
      // } else if (e.key === 'Escape') {
      //   if (selectedAnnotation) {
      //     setSelectedAnnotation(null)
      //   }
      //   setAnnotationInProgress(null)
      //   setToolMode(null)
      // }
    }
    document.addEventListener('keydown', handleKeydown)
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])


  /* MOUSE LISTENER */
  useEffect(() => {
    const handleClick = (event) => {
      registerUsage()
    }
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  /* WINDOW RESIZE */
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < SMALL_SCREEN_THRESHOLD);
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // main tab gutter
  useEffect(() => {
    const returnSplitToDefault = () => {
      db.collection('users').doc(loggedInUser?.uid).update({ centerPaneDividerSplit: [75, 25] });
    };

    const gutterElement = document.querySelector('.main-col-split .gutter-vertical');

    if (gutterElement) {
      gutterElement.addEventListener('dblclick', returnSplitToDefault);
    }

    return () => {
      if (gutterElement) {
        gutterElement.removeEventListener('dblclick', returnSplitToDefault);
      }
    };
  }, [loggedInUser]);

  /*
  APP LAYOUT
  */

  /* LOADING SCENARIO */
  if (loading) {
    return (
      <div className="App">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            margin: '5px',
            height: '100%',
            border: `1px solid ${GREEN}`,
            borderRadius: 5,
            backgroundColor: BACKGROUND,
            color: ORANGE,
            fontSize: 20,
            fontWeight: 'bold'
          }}
        >
          <div className='column center align-center loading-screen'>
            <div className='row align-center'>
              <div>
                Tickerbot
              </div>
              <Icon
                className='margin-top-5'
                icon={'arrow-trend-up'}
                set='sharp-solid'
                size={24}
              />
            </div>
            <Icon
              className='margin-top-10'
              icon='spinner'
              set='sharp-solid'
              size={18}
              spin={true}
            />
          </div>
        </div>
      </div>
    )
  }

  if (isSmallScreen) {
    return (
      <div className="App">
        <LoggedOutMenuBar />
        <div className='body'>
          {openModal}
          <div className='logged-out-screen'>
            <div className='signup-container'>
              <div className='signup-form-container'>
                <div className='signup-context-title center'>
                  Small screen detected.
                </div>
                <div className='signup-context-subtitle center'>
                  Make the screen wider.
                </div>
                <div className='signup-context-subtitle center gray-text margin-top-40' style={{fontSize: 11, fontStyle: 'italic'}}>
                  Tickerbot is currently designed for wide screens only.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /* LOGGED OUT SCENARIO */
  if (!fireBaseToken) {
    switch (loggedOutTab) {
      case 'sign_up':
        return (
          <div className="App">
            <LoggedOutMenuBar />
            <div className='body'>
              {openModal}
              <SignUp subscribeToUserUpdates={subscribeToUserUpdates} />
            </div>
          </div>
        );
      case 'log_in':
        return (
          <div className="App">
            <LoggedOutMenuBar />
            <div className='body'>
              {openModal}
              <Login subscribeToUserUpdates={subscribeToUserUpdates} />
            </div>
          </div>
        )
      case 'reset_pw':
        return (
          <div className="App">
            <LoggedOutMenuBar />
            <div className='body'>
              {openModal}
              <ResetPassword />
            </div>
          </div>
        )
      case 'reset_pw_confirmation':
        return (
          <div className="App">
            <LoggedOutMenuBar />
            <div className='body'>
              {openModal}
              <ResetPasswordConfirmationScreen />
            </div>
          </div>
        )
    }
  }

  /* EMAIL NOT VERIFIED SCENARIO */
  if (!emailVerified) {
    return (
      <div className="App">
        <LoggedOutMenuBar />
        <div className='body'>
          {openModal}
          <div className='logged-out-screen'>
            <div className='signup-container'>
              <div className='signup-form-container'>
                <div className='signup-context-title center'>
                  Verify your email to get started.
                </div>
                <div className='signup-context-subtitle center'>
                  Check your inbox and follow the instructions.
                </div>
                <Button
                  display='Resend verification email'
                  className='margin-top-20'
                  style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
                  isLoading={emailVerBtnLoading}
                  onClick={ async () => {
                    try {
                      setEmailVerBtnLoading(true)
                      await currentUser()?.sendEmailVerification({
                        url: 'http://localhost:3000'
                      })
                      setEmailVerBtnLoading(false)
                    } catch (err) {
                      setEmailVerBtnLoading(false)
                    }
                  }}
                />
                <div className='signup-context-subtitle center gray-text margin-top-40' style={{fontSize: 11, fontStyle: 'italic'}}>
                  Need help? Refresh the page or contact support.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /* TICKERBOT ACCOUNT BUT NO ALPACA ACCOUNT */
  if (!hasAppAccess) {
    return (
      <div className="App">
        <LoggedOutMenuBar />
        <div className='body'>
          {openModal}
          <AlpacaSignUp />
        </div>
      </div>
    )
  }

  let mainColClass = 'main-col'
  if (loggedInUser?.rightPaneClosed && loggedInUser?.leftPaneClosed) {
    mainColClass += ' left-and-right-closed'
  }
  if (loggedInUser?.rightPaneClosed && !loggedInUser?.leftPaneClosed) {
    mainColClass += ' right-closed'
  }
  if (!loggedInUser?.rightPaneClosed && loggedInUser?.leftPaneClosed) {
    mainColClass += ' left-closed'
  }

  const getContent = () => {
    if (appTab === 'account') {
      return <AccountTab />
    } else if (appTab === 'backtest') {
      return <BacktestTab />
    } else {
      return <>
        <div className={mainColClass}>
          <Split
            className={loggedInUser.bottomPaneClosed ? 'main-col-split no-bottom-pane' : 'main-col-split'}
            direction='vertical'
            sizes={loggedInUser.bottomPaneClosed ? [100, 0] : loggedInUser?.centerPaneDividerSplit || [70, 30]}
            minSize={loggedInUser.bottomPaneClosed ? 0 : 37}
            gutterSize={loggedInUser.bottomPaneClosed ? 0 : 5}
            gutterAlign='center'
            cursor='row-resize'
            onDragEnd={(val) => {
              db.collection('users')
                .doc(loggedInUser.uid)
                .update({centerPaneDividerSplit: val})
            }}
          >
            <DividerPane />
            {loggedInUser.bottomPaneClosed ? null : <BottomPane />}
          </Split>
        </div>
        {loggedInUser?.rightPaneClosed ? null : <RightPane />}
      </>
    }
  }

  /* ALL SYSTEMS GO */
  return (
    <div className={'App'}>
      <div className={'body'}>
        {openModal}
        <div className='tab-col'>
          <NewLeftPane />
          {getContent()}
        </div>
      </div>
    </div>
  )
}

export default App;
