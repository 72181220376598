import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
  tableSX_Modal,
} from '../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../types/global_types'
import { getConfig } from '../../config'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { CustomGridOverlay } from '../tabs/CustomGridOverlay'
import { TickerModal } from '../modals/TickerModal'
import { Backtest } from '../../types/backtest_types'
import { EELog } from '../../types/user_types'
import { Icon } from '../reusable'
import { TableDropdown } from '../dropdowns/TableDropdown'
const config = getConfig() as any

interface PositionsTableProps {
  height?: string
  width?: string
  backtest?: Backtest | null
  log?: EELog
  paneIndex?: number
}

export const PositionsTable = (props: PositionsTableProps) => {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'symbol',
      sort: 'asc' as GridSortDirection
    },
  ]);


  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const handleContextMenuClick = (event: any, params: any, openLeft?: boolean) => {
    if (get_contextmenu_off()) return
    event.preventDefault()
    const width = 220
    const clickX = openLeft? event.clientX - (width + 5) : event.clientX + 5
    const clickY = event.clientY - 10
    const symbol = params.row.symbol
    setOpenDropdown(
      <TickerDropdown
        symbol={symbol}
        left={clickX}
        top={clickY}
        width={width}
        onClose={() => setOpenDropdown(null)}
        fromTable={true}
      />
    )
  }

  if (!user || !uld) return null

  let LD_DATA_SOURCE = uld.liveData
  if (props.backtest) {
    LD_DATA_SOURCE = props.backtest.liveData
  }
  if (props.log) {
    LD_DATA_SOURCE = props.log._liveData
  }
  let positionRows: any[] = []
  Object.keys(LD_DATA_SOURCE.POSITIONS).forEach(key => {
    const position = LD_DATA_SOURCE.POSITIONS[key]
    positionRows.push({...position, id: uuid()})
  })

  let parentClassName = 'table-tab'
  let tableClassName = 'table-container'
  if (props.log) {
    parentClassName += ' for-modal'
    tableClassName += ' for-modal'
  }

  return (
    <div className={parentClassName} style={{width: props.width, height: props.height, minHeight: 0, position: 'relative'}}>
      {openDropdown}
      {!props.log && <div className='tab-header'>
        <div>Positions table</div>
        <Icon 
          icon='gear'
          set='regular'
          size={14}
          style={{marginLeft: 12, marginTop: 1}}
          onClick={(e) => {      
            const rect = e.target.getBoundingClientRect()
            if (rect) {
              const width= 175
              const left = rect.left - width + 10
              const top = rect.bottom
              setOpenDropdown(<TableDropdown 
                left={left}
                top={top}
                width={width}
                onClose={() => setOpenDropdown(null)}
                paneIndex={props.paneIndex}
                table='positions'
              />)
            }
          }}
        />
      </div>}
      <div className={tableClassName}>
        <DataGridPro
          className='clickable-grid uppercase-grid'
          rows={positionRows}
          columns={[
            {
              field: 'symbol',
              headerName: 'ticker',
              width: 100,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.symbol}
                </div>
              )
            },
            {
              field: 'qty',
              headerName: 'qty',
              width: 120,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {roundToSigFigs(params.row.qty, params.row.avg_entry_price)}
                </div>
              )
            },
            {
              field: 'current_price',
              headerName: 'current_price',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatNumberForTable(params.row.current_price)}
                </div>
              )
            },
            {
              field: 'avg_entry_price',
              headerName: 'avg_entry_price',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatNumberForTable(params.row.avg_entry_price)}
                </div>
              )
            },
            {
              field: 'cost_basis',
              headerName: 'cost_basis',
              width: 120,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatNumberForTable(params.row.cost_basis)}
                </div>
              )
            },
            {
              field: 'mkt_value',
              headerName: 'mkt_value',
              width: 120,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatNumberForTable(params.row.mkt_value)}
                </div>
              )
            },
            {
              field: 'day_unrealized_pl',
              headerName: 'day_unrealized_pl',
              width: 180,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  className={params.row.day_unrealized_pl >= 0 ? ' pos' : ' neg'}
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatNumberForTable(params.row.day_unrealized_pl)}
                </div>
              )
            },
            {
              field: 'day_unrealized_pl_pc',
              headerName: 'day_unrealized_pl_pc',
              width: 180,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  className={params.row.day_unrealized_pl >= 0 ? ' pos' : ' neg'}
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatPercent(params.row.day_unrealized_pl_pc)}
                </div>
              )
            },
            {
              field: 'unrealized_pl',
              headerName: 'unrealized_pl',
              width: 180,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  className={params.row.unrealized_pl >= 0 ? ' pos' : ' neg'}
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatNumberForTable(params.row.unrealized_pl)}
                </div>
              ),
            },
            {
              field: 'unrealized_pl_pc',
              headerName: 'unrealized_pl_pc',
              width: 180,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  className={params.row.unrealized_pl >= 0 ? ' pos' : ' neg'}
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params, true)
                  }}
                >
                  {formatPercent(params.row.unrealized_pl_pc)}
                </div>
              )
            }

          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          disableColumnReorder
          density='compact'
          components={{
            NoRowsOverlay: () => {
              return (
                <CustomGridOverlay text='No positions.' />
              )
            },
          }}
          sx={{
            ...tableSX,
            ...(props.log ? tableSX_Modal : null)

          }}
          onRowClick={(params, event) => {
            setOpenModal(<TickerModal symbol={params.row.symbol} />)
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total positions:',
          }}
          // hideFooter={props.log ? true : false}
          hideFooter={true}
        />
      </div>
    </div>
  )
}