import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react'
import {
  openModalAtom,
  userLiveDocAtom,
  loggedInUserAtom,
  consoleOutputAtom,
  selectedCodeEditorSlotAtom,
  strategiesWithUnsavedChangesAtom,
  cmdSAtom, cmdTAtom, cmdOAtom
} from '../../types/global_types';
import { DropdownMenu, DropdownSelect, Icon, Switch } from '../reusable';
import { CodeEditor } from './CodeEditor';
import { Strategy, User } from '../../types/user_types';
import { db } from '../../firebase';
import { StrategyDropdown } from '../dropdowns/StrategyDropdown';
import { createEELog, formatDateForTable, timeAgo, INTERVALS } from '../../logic/u';
import { CreateBacktestModal } from '../modals/CreateBacktestModal';
import { AddTickersModal } from '../modals/AddTickersModal';

interface CodeEditorWrapperProps {
  height?: string
  width?: string
  // codeEditorSlot: number
  strategy?: Strategy
  paneIndex?: number
}

export const CodeEditorWrapper = (props: CodeEditorWrapperProps) => {
  const {strategy, paneIndex} = props

  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)
  const [selectedCodeEditorSlot, setSelectedCodeEditorSlot] = useAtom(selectedCodeEditorSlotAtom)
  const [ dropdown, setDropdown ] = useState<any>()
  const [, setOpenModal] = useAtom(openModalAtom)

  const [cmdS] = useAtom(cmdSAtom)
  const [cmdT] = useAtom(cmdTAtom)
  const [cmdO] = useAtom(cmdOAtom)

  const page_load_start_ts = new Date().getTime()

  // Save on cmd + s, see keyboard listener in app
  useEffect(() => {
    if (!cmdS) return
    if (!strategy || !user) return
    save_strategy_code(strategy, user, strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges)
  }, [cmdS])

  let codeEditorWrapperClass = 'code-editor-wrapper'
  
  if (!strategy) {
    codeEditorWrapperClass += ' empty'
  }

  let emptyHeaderClass = 'tab-header empty'
  let emptyBoxClass = 'empty-box'
  let emptyText = 'Click here'
  // if (selectedCodeEditorSlot === codeEditorSlot) {
  //   emptyHeaderClass += ' selected'
  //   emptyBoxClass += ' selected'
  //   emptyText = 'Choose a strategy...'
  // }

  if (!user || !uld) return null

  if (!strategy) return (
    // <div className={codeEditorWrapperClass}>
    //   <div className={emptyHeaderClass}>
    //   </div>
    //   <div
    //     className={emptyBoxClass}
    //     onClick={() => {
    //       setSelectedCodeEditorSlot(selectedCodeEditorSlot ? null : codeEditorSlot)
    //     }}
    //   >
    //     {emptyText}
    //   </div>
    // </div>
    null
  )

  return (
    <div className={codeEditorWrapperClass} style={{width: props.width, height: props.height, minHeight: 0, position: 'relative'}}>
      {dropdown}
      <div className='tab-header'>
        <div className='row align-center'>
          <div className={user.paneSettingsCH1 !== '1' ? 'strategy-tab-name smaller' : 'strategy-tab-name'}>
            {strategy?.name}
          </div>
          {strategiesWithUnsavedChanges[strategy.id] ? <div
            className='tab-header-btn'
            style={{marginLeft: 12}}
            onClick={async () => {
              save_strategy_code(strategy, user, strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges)
            }}
          >
            <Icon
              icon='floppy-disk'
              className='tab-header-btn-icon'
              hoverText='Run strategy once'
              set='sharp-solid'
              size={12}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for css
              }}
            />
            <div>
              Save
            </div>
          </div> : null}
        </div>

        {/* Right */}
        <div className='row'>
          <div
            className={'tab-header-btn'}
            onClick={() => {
              setOpenModal(<CreateBacktestModal strategyId={strategy.id} />)
            }}
          >
            <Icon
              // icon='circle-plus'
              icon='flask-vial'
              className='tab-header-btn-icon'
              set='sharp-solid'
              size={13}
              style={{marginTop: .5, marginRight: 5}}
              onClick={() => {
                // for css
              }}
            />
            <div>Backtest</div>
          </div>          
          <Icon
            icon='gear'
            set='regular'
            size={14}
            style={{marginLeft: 12, marginTop: 5.5}}
            onClick={(e) => {
              const rect = e.target.getBoundingClientRect()
              if (rect) {
                const width= 175
                const left = rect.left - width + 10
                const top = rect.bottom
                setDropdown(<StrategyDropdown
                  left={left}
                  top={top}
                  strategyObj={strategy}
                  width={width}
                  onClose={() => setDropdown(null)}
                  paneIndex={paneIndex}
                />)
              }
            }}
          />
        </div>
      </div>
      <CodeEditor strategy={strategy} />
    </div>
  )
}

const save_strategy_code = async(strategy: Strategy, user: User, strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges) => {

  const unsavedCode = strategiesWithUnsavedChanges[strategy.id]

  // Extract function names
  const function_metadata = extract_function_names(unsavedCode)

  // Find minimum_interval
  let min_interval = '1d';
  let min_interval_ms = 24 * 60 * 60 * 1000;
  function_metadata.forEach((funct: any) => {
    const func_interval = funct.params.interval
    const func_interval_ms = INTERVALS[func_interval || '1d']
    if (func_interval_ms < min_interval_ms) {
      min_interval = func_interval || '1d';
      min_interval_ms = func_interval_ms;
    }
  })

  // Assemble combined list of tickers
  const tickers: string[] = []
  function_metadata.forEach((funct: any) => {
    funct.tickers.forEach((ticker) => {
      if (!tickers.includes(ticker)) tickers.push(ticker)
    })
  })

  // Identify any charts not included in the code
  const charts_on_user = Object.keys(user.charts)
  const tickers_unaccounted_for = tickers.filter(ticker => !charts_on_user.includes(ticker))

  // Update charts on user
  const updatedCharts = {...user.charts}
  tickers_unaccounted_for.forEach(ticker => {
    updatedCharts[ticker] = {
      symbol: ticker,
      display: 'ADD DISPLAY NAME',
      added_automatically_from_strategy: strategy.id,
      //@ts-ignore
      indicators: []
    }
  })

  // Remove any charts that were added automatically from this strategy but are no longer in the code
  Object.keys(updatedCharts).forEach(ticker => {
    if (updatedCharts[ticker].added_automatically_from_strategy === strategy.id) {
      if (!tickers.includes(ticker)) {
        delete updatedCharts[ticker]
      }
    }
  })

  const userRef = db.collection('users').doc(user.uid)
  await userRef.update({ charts: updatedCharts })


  if (unsavedCode === undefined) return

  const updatedStrategy: Strategy = {
    ...strategy,
    code: unsavedCode,
    updatedAt: new Date(),
    function_metadata,
    min_interval,
    tickers
  }

  await db.collection('users')
    .doc(user.uid)
    .collection('strategies')
    .doc(updatedStrategy.id)
    .set(updatedStrategy)

  let newState = {...strategiesWithUnsavedChanges}
  delete newState[strategy.id]
  setStrategiesWithUnsavedChanges(newState || {})
}

// Pulls function metadata out of user code
const extract_function_names = (code: string) => {
  // Helper function to remove comments from the code
  const removeComments = (code: string): string => {
    // Remove multi-line comments (/* ... */)
    code = code?.replace(/\/\*[\s\S]*?\*\//g, '');
    // Remove single-line comments (// ...)
    code = code?.replace(/\/\/.*$/gm, '');
    return code;
  };

  // Remove comments from the input code
  const codeWithoutComments = removeComments(code);

  const regex = /strategy\.(on_interval|on_trigger)\s*\(/g;
  const results: any[] = [];
  let match;

  while ((match = regex.exec(codeWithoutComments)) !== null) {
    const type = match[1];
    const startIndex = match.index + match[0].length; // position after the opening parenthesis
    // Now, we need to find the matching closing parenthesis
    let parentheses = 1;
    let currentIndex = startIndex;
    const codeLength = codeWithoutComments.length;

    while (currentIndex < codeLength && parentheses > 0) {
      const char = codeWithoutComments[currentIndex];
      if (char === '(') {
        parentheses++;
      } else if (char === ')') {
        parentheses--;
      } else if (char === '"' || char === "'" || char === '`') {
        // Skip over strings to avoid counting parentheses inside strings
        const quoteType = char;
        currentIndex++;
        while (currentIndex < codeLength && codeWithoutComments[currentIndex] !== quoteType) {
          // Handle escaped quotes
          if (codeWithoutComments[currentIndex] === '\\') {
            currentIndex += 2; // Skip escaped character
          } else {
            currentIndex++;
          }
        }
      }
      currentIndex++;
    }

    const argsString = codeWithoutComments.substring(startIndex, currentIndex - 1); // Exclude the closing parenthesis

    // Now, parse argsString to get the arguments
    const splitArguments = (str: string): string[] => {
      const args: string[] = [];
      let currentArg = '';
      let parentheses = 0;
      let quotes: string | null = null;
      let escapeNext = false;

      for (let i = 0; i < str.length; i++) {
        const char = str[i];

        if (escapeNext) {
          currentArg += char;
          escapeNext = false;
          continue;
        }

        if (char === '\\') {
          currentArg += char;
          escapeNext = true;
          continue;
        }

        if (quotes) {
          if (char === quotes) {
            quotes = null;
          } else if (char === '\\') {
            escapeNext = true;
          }
          currentArg += char;
          continue;
        } else {
          if (char === '"' || char === "'" || char === '`') {
            quotes = char;
            currentArg += char;
            continue;
          }
        }

        if (char === '(') {
          parentheses++;
        } else if (char === ')') {
          parentheses--;
        }

        if (char === ',' && parentheses === 0 && !quotes) {
          args.push(currentArg.trim());
          currentArg = '';
        } else {
          currentArg += char;
        }
      }

      if (currentArg.trim() !== '') {
        args.push(currentArg.trim());
      }

      return args;
    };

    const stripQuotes = (str: string): string => {
      if (
        (str.startsWith('"') && str.endsWith('"')) ||
        (str.startsWith("'") && str.endsWith("'")) ||
        (str.startsWith('`') && str.endsWith('`'))
      ) {
        return str.slice(1, -1);
      }
      return str;
    };

    const args = splitArguments(argsString);

    const params: any = {};
    const name = stripQuotes(args[0]);
    let functionCode = '';

    if (type === 'on_interval') {
      const intervalArg = args[1];
      const interval = intervalArg ? stripQuotes(intervalArg) : undefined;
      params.interval = interval;
      functionCode = args[2] || '';
    } else {
      functionCode = args[1] || '';
    }

    // If functionCode is a function, extract its body
    // Updated function type detection logic
    if (
      functionCode.startsWith('function') ||
      functionCode.startsWith('()') ||
      functionCode.startsWith('(function') ||
      functionCode.startsWith('async function') ||
      functionCode.startsWith('async ()') ||
      functionCode.startsWith('async()')
    ) {
      // It's an inline function, keep as is
    } else if (
      functionCode.startsWith('() =>') ||
      functionCode.startsWith('async () =>') ||
      functionCode.startsWith('async() =>')
    ) {
      // Arrow function, keep as is
    } else {
      functionCode = ''; // Not a function
    }

    // Now, from functionCode, we need to extract tickers used
    const tickerRegex = /(tb\.ticker|portfolio\.(buy|sell))\s*\(\s*(['"`])([^'"`]+)\3/g;
    const tickers: string[] = [];
    let tickerMatch;
    while ((tickerMatch = tickerRegex.exec(functionCode)) !== null) {
      tickers.push(tickerMatch[4]);
    }

    results.push({ type, name, params, tickers });
  }

  return results;
};




