// cancels all open orders for a symbol or just one

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Modal } from '../reusable'
import {
  loggedInUserAtom,
  openModalAtom,
  ordersAtom,
  phantomLogAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { currentUser, db } from '../../firebase'
import { getConfig } from '../../config'
import axios from 'axios'
import { TickerModal } from './TickerModal'
import { set_phantom_log, updateLiveData } from '../../logic/u'
import { make_create_order_string } from '../../logic/make_create_order_string'
const config = getConfig() as any

interface CancelOpenOrderModalProps {
  symbol: string
  orderId?: string // cancels just one if specified
  showBack?: boolean
}

export const CancelOpenOrderModal = (props: CancelOpenOrderModalProps) => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [ , setPhantomLog ] = useAtom(phantomLogAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [orders, setOrders] = useAtom(ordersAtom)
  const {symbol, orderId} = props
  const openOrders = orders.working.filter((o: any) => o.symbol === symbol) || []
  const openOrderIds = openOrders.map((o: any) => {
    return o.id
  })

  if (!user || !uld) return null

  let titleText = 'Cancel open orders?'
  let buttonText = 'Cancel orders'
  let content = <div>
    There are no open orders for <span className='bold'>{symbol}</span>.
  </div>
  if (openOrders.length) {
    titleText = `Cancel open orders?`
    buttonText = `Cancel orders`
    content = <div className=''>
      The open orders for <span className='bold'>{symbol}</span> will be canceled.
      <br />
      <br />
      {openOrders.map(o => {
        return <>
          {make_create_order_string(o)}
          <br />
          <br />
        </>
      })}
    </div>
  }

  if (orderId) {
    titleText = `Cancel open order?`
    buttonText = 'Cancel order'
    content = <div className=''>
      This open order for <span className='bold'>{symbol}</span> will be canceled.
      <br />
      <br />
      {make_create_order_string(openOrders.find(o => o.id === orderId))}
    </div>
  }

  return (
    <Modal
      title={titleText}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          {content}
        </div>
      ]}
      yesButtonText={buttonText}
      isDanger
      twoStepConfirmation={true}
      isButtonLoading={buttonIsLoading}
      backIconOnClick={props.showBack ? () => setOpenModal(props.symbol ? <TickerModal symbol={props.symbol} /> : null) : null}
      onYes={ async () => {
        try {
          setOpenModal(null)
          set_phantom_log('Canceling order...', uld, setPhantomLog)

          // setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const ordersToCancel: string[] = []
          if (orderId) {
            ordersToCancel.push(orderId)
          } else {
            if (openOrderIds.length) {
              ordersToCancel.push(...openOrderIds)
            }
          }

          for (const id of ordersToCancel) {
            const url = `${config.api_root_url}deleteOrder?orderId=${id}&create_ee_log=true`
            await axios.delete(url, { headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            } })
          }
          updateLiveData(user)
          // setButtonIsLoading(false)
        } catch (error) {
          // setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

